$(document).ready(function(){

});

$('body').on('click','.add-answer',function(e){
        e.preventDefault();
        $('#add-answer-form').show();
        $('#add-answer-button').hide();
});

$('body').on('click','#save-answer-button',function(e){
    var $special;
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        e.preventDefault();
        if ($('#answer_special_input').prop('checked') == true) {
             $special = 1;
        } else {
            $special = 0;
        }

        var formData = {
            answer: $('#answer_name_input').val(),
            value: $('#answer_value_input').val(),
            order: $('#answer_order_input').val(),
            special: $special,
            question_id: $('#answer_question_input').val(),
        };


        $.ajax({
            type: "POST",
            url: "/addanswer",
            data: formData,
            dataType: 'html',
            success: function (data) {
                $("#answers-table-wrapper").html( data );
                $("#quest-answers-table").DataTable({
                    "paging": false
                });
                $('#answer_name_input').val('');
                $('#answer_value_input').val('1');
                $('#answer_order_input').val('1');
                $('#answer_special_input').prop( "checked", false );
                $('#add-answer-form').hide();
                $('#add-answer-button').show();
            },
            error: function (data) {
                console.log('Error:', data);
            }
        });
});

$('body').on('click','.delete-answer',function(e){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    e.preventDefault();

    var answer_id = $(this).siblings( "input" ).val(),
        formData = {
            answer_id: answer_id,
            question_id: $('#answer_question_input').val(),
        };

    $.ajax({
        type: "POST",
        data: formData,
        url: "/delanswer",
        success: function (data) {
            $("#answers-table-wrapper").html( data );
            $("#quest-answers-table").DataTable({
                "paging": false
            });
        },
        error: function (data) {
            console.log('Error:', data);
        }
    });
});

$('body').on('click','.delete-question',function(e){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    e.preventDefault();

    var quest_id = $(this).siblings( "input" ).val(),
        formData = {
            question_id: quest_id,
            questionnaire_id: $('#question_questionnaire_input').val(),
        };

    $.ajax({
        type: "POST",
        data: formData,
        url: "/delquestion",
        success: function (data) {
            console.log(data);
            $("#questions-table-wrapper").html( data );
            $("#quest-questions-table").DataTable({
                "paging": false
            });
        },
        error: function (data) {
            console.log('Error:', data);
        }
    });
});

$('body').on('click','.add-question',function(e){
    e.preventDefault();
    $('#add-question-form').show();
    $('#add-question-button').hide();
});

$('body').on('click','.save-question',function(e){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    e.preventDefault();

    var formData = {
        question: $('#question_name_input').val(),
        group: $('#question_group_input').val(),
        required: $('#question_required_input').val(),
        order: $('#question_order_input').val(),
        questionnaire_id: $('#question_questionnaire_input').val(),
        created_by: $('#question_created_input').val(),
    };

    console.log(formData);

    $.ajax({
        type: "POST",
        url: "/addquestion",
        data: formData,
        dataType: 'html',
        success: function (data) {
            $("#questions-table-wrapper").html( data );
            $("#quest-questions-table").DataTable({
                "paging": false
            });
            $('#question_name_input').val('')
            $('#question_order_input').val('1')
            $('#question_group_input').val('1')

            $('#add-question-form').hide();
            $('#add-question-button').show();

        },
        error: function (data) {
            console.log('Error:', data);
        }
    });
});

$('body').on('click','.copy-quest',function(e){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    e.preventDefault();

    var quest_id = $(this).siblings( "input" ).val(),
        formData = {
            questionnaire_id: quest_id
        };
    console.log(formData);

    $.ajax({
        type: "POST",
        url: "/copyquestionnaire",
        data: formData,
        dataType: 'html',
        success: function (data) {
            $("#quest-table-wrapper").html( data );
            $("#questionnaire-table").DataTable({
                "paging": false
            });
        },
        error: function (data) {
            console.log('Error:', data);
        }
    });
});

$('body').on('click','.enable-quest',function(e){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    e.preventDefault();

    var quest_id = $(this).siblings( "input" ).val(),
        formData = {
            questionnaire_id: quest_id
        };
    console.log(formData);

    $.ajax({
        type: "POST",
        url: "/enablequestionnaire",
        data: formData,
        dataType: 'html',
        success: function (data) {
            $("#quest-table-wrapper").html( data );
            $("#questionnaire-table").DataTable({
                "paging": false
            });
        },
        error: function (data) {
            console.log('Error:', data);
        }
    });
});
$('body').on('change','.question-answer',function(e){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    e.preventDefault();
    var answ_id = $(this).val(),
        quest_blk = $(this).closest( ".question-block" ),
        quest_id = quest_blk.data('question'),
        comment_data = quest_blk.find('textarea').val(),
        formData = {
            question_id: quest_id,
            answer_id: answ_id,
            comment: comment_data
        };

    $.ajax({
        type: "POST",
        url: "/result/save",
        data: formData,
        dataType: 'html',
        beforeSend: function() {
            $(".question-"+ quest_id +"-saving").show();
        },
        success: function (data) {
            $(".question-"+ quest_id +"-saved").show();
            var dataParsed = $.parseJSON(data);
            $("#total_q").text(dataParsed['total']);

            $("#answered_q").text(dataParsed['answers']);


        },
        error: function (data) {
            console.log('Error:', data);
        },
        complete: function(){
            $(".question-"+ quest_id +"-saving").hide();
        }
    });
});

$('body').on('click','.add-comment',function(e){
    e.preventDefault();
    $('#add-comment-form').show();
    $('#add-comment-button').hide();
});

$('body').on('click','#save-comment-button',function(e){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
    var highlight;
    e.preventDefault();
    if ($('#comment_highlight_input').prop('checked') == true) {
        $highlight = 1;
    } else {
        $highlight = 0;
    }

    var formData = {
        name: $('#comment_name_input').val(),
        description: $('#comment_description_input').val(),
        highlight: $highlight,
        order: $('#comment_order_input').val(),
        question_id: $('#comment_question_input').val(),
    };

    $.ajax({
        type: "POST",
        url: "/addcomment",
        data: formData,
        dataType: 'html',
        success: function (data) {
            $("#comment-table-wrapper").html( data );
            $("#quest-comment-table").DataTable({
                "paging": false
            });
            $('#comment_name_input').val('');
            $('#comment_description_input').val('1');
            $('#comment_order_input').val('1');
            $('#comment_highlight_input').prop( "checked", false );

            $('#add-comment-form').hide();
            $('#add-comment-button').show();
        },
        error: function (data) {
            console.log('Error:', data);
        }
    });
});

$('body').on('click','.delete-comment',function(e){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    e.preventDefault();

    var comment_id = $(this).siblings( "input" ).val(),
        formData = {
            comment_id: comment_id,
            question_id: $('#comment_question_input').val(),
        };

    $.ajax({
        type: "POST",
        data: formData,
        url: "/delcomment",
        success: function (data) {
            $("#comment-table-wrapper").html( data );
            $("#quest-comment-table").DataTable({
                "paging": false
            });
        },
        error: function (data) {
            console.log('Error:', data);
        }
    });
});
